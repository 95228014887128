import { actionLogs } from './actionLogs'
import { agencies } from './agencies'
import { auth } from './auth'
import { campaignDownload } from './campaignDownload'
import { commission } from './commission'
import { commissionDownload } from './commissionDownload'
import { contracts } from './contracts'
import { contractStatuses } from './contractStatuses'
import { fileUpload } from './fileUpload.ts'
import { hozen } from './hozen'
import { manual } from './manual'
import { news } from './news'
import { notifications } from './notifications'
import { oliveUsers } from './oliveUsers'
import { recruiters } from './recruiters'
import { supportCommissionDownload } from './supportCommissionDownload'

export const createApiClient = () => {
  return {
    auth,
    oliveUsers,
    recruiters,
    agencies,
    contracts,
    actionLogs,
    fileUpload,
    contractStatuses,
    commission,
    commissionDownload,
    supportCommissionDownload,
    campaignDownload,
    manual,
    news,
    notifications,
    hozen,
  }
}
